import React, { useState } from "react";
import styled from "styled-components";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import QRCode from "qrcode";
import HMAC from "crypto-js/hmac-sha1";
import Hex from "crypto-js/enc-hex";
import Base32 from "hi-base32";
import { useNavigate } from "react-router-dom";

const db = getFirestore();

const generateTOTP = (secret, timeStep = 30, digits = 6) => {
  const epoch = Math.floor(Date.now() / 1000);
  const counter = Math.floor(epoch / timeStep);

  // Decode Base32 secret as bytes
  const decodedSecret = Base32.decode.asBytes(secret.replace(/ /g, "").toUpperCase());
  const hexKey = Hex.parse(decodedSecret.map((byte) => byte.toString(16).padStart(2, "0")).join(""));

  // Convert counter to an 8-byte buffer
  const hexCounter = counter.toString(16).padStart(16, "0");
  const counterBytes = Hex.parse(hexCounter);

  // Generate HMAC using the secret and counter
  const hmac = HMAC(counterBytes, hexKey);

  // Dynamic truncation to extract 4 bytes
  const offset = parseInt(hmac.toString(Hex).slice(-1), 16);
  const binary = parseInt(hmac.toString(Hex).substr(offset * 2, 8), 16) & 0x7fffffff;

  // Compute the OTP value
  const otp = binary % Math.pow(10, digits);
  return otp.toString().padStart(digits, "0");
};

const generateRandomSecret = () => {
  // 20 random bytes
  const randomBuffer = new Uint8Array(20);
  window.crypto.getRandomValues(randomBuffer);

  // Convert to Base32 and return
  return Base32.encode(randomBuffer).replace(/=/g, "");
};

const verifyTOTP = (code, secret, timeStep = 30, window = 1) => {
  const epoch = Math.floor(Date.now() / 1000);
  const currentCounter = Math.floor(epoch / timeStep);

  // Check the code against counters within the window
  for (let i = -window; i <= window; i++) {
    const counter = currentCounter + i;
    const expectedCode = generateTOTP(secret, timeStep);
    if (expectedCode === code) {
      return true;
    }
  }

  return false;
};

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [qrCode, setQrCode] = useState(""); // QR Code URL
  const [secret, setSecret] = useState(""); // Secret for TOTP
  const [isTwoFAVerification, setIsTwoFAVerification] = useState(false); // Show verification popup
  const [verificationCode, setVerificationCode] = useState("");
  const [tempSecret, setTempSecret] = useState(""); // Temporary TOTP secret
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(""); // Reset error on new login attempt
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      const userDocRef = doc(db, "users", email); // Reference to user's Firestore document
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const { totpSecret, lastLoginTime } = userDoc.data();
        const currentTime = Date.now();
        const lastLoginTimeMillis = lastLoginTime ? lastLoginTime.toMillis() : 0;

        if (!totpSecret || currentTime - lastLoginTimeMillis > 1 * 60 * 1000) {
          if (!totpSecret) {
            const newSecret = generateRandomSecret();
            setTempSecret(newSecret); // Store secret locally

            const otpauthUrl = `otpauth://totp/AdminApp?secret=${newSecret}&issuer=AdminApp`;
            try {
              const qrCodeData = await QRCode.toDataURL(otpauthUrl);
              setQrCode(qrCodeData); // Display QR code
            } catch (qrError) {
              console.error("Error generating QR Code:", qrError);
              setError("Failed to generate QR code.");
            }
          } else {
            setSecret(totpSecret); // Use existing secret for verification
          }
          setIsTwoFAVerification(true); // Show verification modal
        } else {
          navigate("/dashboard");
        }
      } else {
        const newSecret = generateRandomSecret();
        setTempSecret(newSecret); // Store secret locally

        const otpauthUrl = `otpauth://totp/AdminApp?secret=${newSecret}&issuer=AdminApp`;
        try {
          const qrCodeData = await QRCode.toDataURL(otpauthUrl);
          setQrCode(qrCodeData); // Display QR code
        } catch (qrError) {
          console.error("Error generating QR Code:", qrError);
          setError("Failed to generate QR code.");
        }
      }
    } catch (err) {
      console.error("Login error:", err.message || err);
      setError("Login failed. Please check your credentials.");
    }
  };

  const handleVerify = async () => {
    const isValid = verifyTOTP(verificationCode, tempSecret || secret);

    if (isValid) {
        alert("2FA Verification successful!");

        if (tempSecret) {
            await setDoc(
                doc(db, "users", email),
                { 
                    totpSecret: tempSecret, 
                    lastLoginTime: new Date(), 
                    twoFAVerified: true 
                },
                { merge: true }
            );
            setSecret(tempSecret);
            setTempSecret("");
        } else {
            await setDoc(
                doc(db, "users", email),
                { 
                    lastLoginTime: new Date(), 
                    twoFAVerified: true 
                },
                { merge: true }
            );
        }

        setIsTwoFAVerification(false);
        navigate("/dashboard", { replace: true });
    } else {
        alert("Invalid 2FA Code. Please try again.");
    }
};


  return (
    <LoginContainer>
      <LoginForm onSubmit={handleLogin}>
        <Title>CRM Login</Title>
        <Input
          type="email"
          placeholder="Enter Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onFocus={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          required
        />
        <Input
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onFocus={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
          required
        />
        <SubmitButton type="submit">LOGIN</SubmitButton>
      </LoginForm>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {qrCode && (
        <QrCodeContainer>
          <QrCodeTitle>Scan this QR Code with Google Authenticator</QrCodeTitle>
          <QrCodeImage src={qrCode} alt="QR Code for 2FA" />
          <VerifyPrompt onClick={() => setIsTwoFAVerification(true)}>
            Continue to Verify Code
          </VerifyPrompt>
        </QrCodeContainer>
      )}

      {isTwoFAVerification && (
        <TwoFAVerificationModal>
          <ModalTitle>2FA Verification</ModalTitle>
          <ModalInput
            type="text"
            placeholder="Enter Code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            onFocus={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            required
          />
          <VerifyButton onClick={handleVerify}>VERIFY</VerifyButton>
          <VerifyPrompt onClick={() => setIsTwoFAVerification(false)}>
            Return
          </VerifyPrompt>
        </TwoFAVerificationModal>
      )}
    </LoginContainer>
  );
};

export default Login;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90vh;
  width: 90vw;
`;

const LoginForm = styled.form`
margin-top: 50px;
  text-align: center;
`;

const Title = styled.h2`
  color: #ff5f1f;
  font-size: 2rem;
  font-family: "RetroFont", sans-serif;
  margin-bottom: 20px;
  text-shadow: 0 0 5px #ff5f1f, 0 0 10px #ff5f1f;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 2px solid #ff5f1f;
  background-color: #333333;
  color: #fff;
  font-family: "RetroFont", sans-serif;
  font-size: 1rem;
  text-align: center;
  outline: none;
  box-shadow: 0 0 8px rgba(255, 95, 31, 0.6);
`;

const SubmitButton = styled.button`
  background-color: #ff5f1f;
  color: black;
  font-family: "RetroFont", sans-serif;
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 8px rgba(255, 95, 31, 0.6);

  &:hover {
    background-color: #d94d1a;
    box-shadow: 0 0 12px rgba(217, 77, 26, 0.9);
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
`;

const QrCodeContainer = styled.div`
  margin-top: px;
  text-align: center;
`;

const QrCodeTitle = styled.h3`
  color: #ff5f1f;
  font-family: "RetroFont", sans-serif;
`;

const QrCodeImage = styled.img`
  width: 200px;
  height: 200px;
  margin-top: 10px;
  border: 2px solid #ff5f1f;
`;

const VerifyPrompt = styled.button`
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  background: transparent;
  color: #ff5f1f;
  font-family: "RetroFont", sans-serif;
  font-size: 1rem;
  text-decoration: underline;
  border: none;
  cursor: pointer;

  &:hover {
    color: #d94d1a;
    text-shadow: 0 0 8px #d94d1a;
  }
`;

const TwoFAVerificationModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #1a1a2e, #28284a);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.8);
  z-index: 1000;
  width: 90%;
  max-width: 400px; /* Limit modal size */
  transform: translate(-50%, -50%);
  animation: bobbing 2s infinite ease-in-out, glowing-border 3s infinite linear;

  /* Glowing border animation */
  border: 3px solid #ff5f1f;
  background-clip: padding-box;

  /* Keyframes for bobbing effect */
  @keyframes bobbing {
    0%, 100% {
      transform: translate(-50%, -48%);
    }
    50% {
      transform: translate(-50%, -52%);
    }
  }

  /* Keyframes for glowing border */
  @keyframes glowing-border {
    0% {
      box-shadow: 0 0 5px #ff5f1f, 0 0 15px #ff5f1f, 0 0 30px #d96e28;
      border-color: #ff5f1f;
    }
    50% {
      box-shadow: 0 0 10px #d96e28, 0 0 20px #ffb16c, 0 0 40px #ff5f1f;
      border-color: #ff5f1f;
    }
    100% {
      box-shadow: 0 0 5px #ff5f1f, 0 0 15px #ff5f1f, 0 0 30px #d96e28;
      border-color: #ff5f1f;
    }
  }
`;

const ModalTitle = styled.h3`
  color: #ff5f1f;
  font-family: "RetroFont", sans-serif;
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: center; /* Ensure the text stays centered */
  text-shadow: 0 0 3px #ff5f1f, 0 0 15px #d96e28;
`;

const ModalInput = styled.input`
  width: 90%;
  max-width: 350px;
  padding: 12px;
  margin: 10px 0 20px; /* Add spacing between input and other elements */
  border: 2px solid #ff5f1f;
  background-color: #1d1d35;
  color: #ffffff;
  font-family: "RetroFont", sans-serif;
  font-size: 1rem;
  text-align: center;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 140, 66, 0.5);
`;


const VerifyButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ff5f1f;
  color: black;
  font-family: "RetroFont", sans-serif;
  font-size: 1rem;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #d94d1a;
    box-shadow: 0 0 12px rgba(217, 77, 26, 0.9);
  }
`;