import React, { useState, useRef, useEffect } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    font-family: 'RetroFont', serif;
    overflow: hidden;
  }
`;

const ContactForm = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // For custom error message
    const containerRef = useRef(null);
    const formRef = useRef(null);
    const navigate = useNavigate();

    const menuItems = ["Submit", "Return"];

    const [formData, setFormData] = useState({
        from_name: '',
        user_email: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault(); // Prevent default form submission behavior

        const templateParams = {
            from_name: formData.from_name,
            user_email: formData.user_email,
            subject: formData.subject,
            message: formData.message,
        };

        emailjs.send('service_e7k2uxi', 'template_nqsiufo', templateParams, '7afYu2Y7F6P0kbsDF')
            .then((response) => {
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({
                    from_name: '',
                    user_email: '',
                    subject: '',
                    message: ''
                });

                // Clear the success message after 2 seconds
                setTimeout(() => {
                    setSuccessMessage('');
                }, 2000);
            }, (err) => {
                setErrorMessage('Failed to send the message. Please try again later.');
                console.log(err);

                // Clear the error message after 2 seconds
                setTimeout(() => {
                    setErrorMessage('');
                }, 2000);
            });
    };

    const handleReturn = () => {
        navigate('/');
    };

    const validateAndSubmit = (e) => {
        if (e) e.preventDefault(); // Ensure default behavior is prevented
        // Check if the form is valid using native validation
        if (formRef.current.checkValidity()) {
            formRef.current.requestSubmit(); // Request form submission
        } else {
            setErrorMessage('Please check all fields are correctly inputted.');

            // Clear the error message after 2 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
        }
    };

    const handleKeyDown = (e) => {
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return; // Skip if focus is inside input

        if (e.key === 'ArrowLeft') {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + menuItems.length) % menuItems.length);
        } else if (e.key === 'ArrowRight') {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
        } else if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default behavior when pressing Enter
            if (selectedIndex === 0) {
                validateAndSubmit(e); // Validate and request submission with "Enter"
            } else if (selectedIndex === 1) {
                handleReturn(); // Handle "Return"
            }
        }
        if (containerRef.current) {
            containerRef.current.focus();
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.focus(); // Focus the container to listen to key presses
        }
    }, []);

    return (
        <>
            <GlobalStyle />
            <ScreenContainer ref={containerRef} tabIndex="0" onKeyDown={handleKeyDown}>
                <FormContainer>
                    <FormTitle>Contact Form</FormTitle>
                    {successMessage && <AnimatedMessage success>{successMessage}</AnimatedMessage>}
                    {errorMessage && <AnimatedMessage error>{errorMessage}</AnimatedMessage>}
                    <StyledForm ref={formRef} onSubmit={handleSubmit}>
                        <Input
                            type="text"
                            name="from_name"
                            value={formData.from_name}
                            placeholder="Name"
                            onChange={handleChange}
                            onFocus={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            required
                        />
                        <Input
                            type="email"
                            name="user_email"
                            value={formData.user_email}
                            placeholder="Email"
                            onChange={handleChange}
                            onFocus={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            required
                        />
                        <Input
                            type="text"
                            name="subject"
                            value={formData.subject}
                            placeholder="Subject"
                            onChange={handleChange}
                            onFocus={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            required
                        />
                        <Textarea
                            name="message"
                            value={formData.message}
                            placeholder="Message"
                            onChange={handleChange}
                            onFocus={(e) => e.stopPropagation()}
                            onKeyDown={(e) => e.stopPropagation()}
                            required
                        />
                    </StyledForm>

                    {/* Navigation Menu for Submit/Return */}
                    <Menu>
                        {menuItems.map((item, index) => (
                            <MenuItem
                                key={index}
                                selected={index === selectedIndex}
                                onMouseEnter={() => setSelectedIndex(index)}
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent default for mouse click
                                    if (index === 0) validateAndSubmit(e); // Handle Submit
                                    else handleReturn(); // Handle "Return"
                                }}
                            >
                                {index === selectedIndex && <Arrow>&gt;</Arrow>}
                                <MenuText className={selectedIndex === index ? 'blinking' : ''}>{item}</MenuText>
                            </MenuItem>
                        ))}
                    </Menu>
                </FormContainer>
            </ScreenContainer>
        </>
    );
};

export default ContactForm;

const slideIn = keyframes`
    from {
        transform: translate(-50%, -100%);  /* Adjust to stay horizontally centered */
        opacity: 0;
    }
    to {
        transform: translate(-50%, 0);  /* Keep horizontally centered */
        opacity: 1;
    }
`;

const slideOut = keyframes`
    from {
        transform: translate(-50%, 0);  /* Start from the center */
        opacity: 1;
    }
    to {
        transform: translate(-50%, -100%);  /* Move up but stay centered */
        opacity: 0;
    }
`;

const AnimatedMessage = styled.div`
    font-size: 1.2rem;
    color: ${({ success, error }) => (success ? '#00FF00' : error ? '#FF0033' : '#FF5F1F')}; /* Handle both success and error */
    background-color: #1A1A1A;
    border: 2px solid ${({ success, error }) => (success ? '#00FF00' : error ? '#FF0033' : '#FF5F1F')}; /* Handle both success and error */
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-family: 'RetroFont', sans-serif;
    animation: ${slideIn} 0.5s ease, ${slideOut} 0.5s ease 1.5s forwards; /* Animation for in and out */
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);  /* Center horizontally */
    width: 80%;
    max-width: 400px;
    text-align: center;
    z-index: 10;
`;

const ScreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #2D2A4A, #1A1A1A);
    position: relative;

    /* Mobile Support */
    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const FormContainer = styled.div`
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    color: #A9A9A9;
    text-align: center;

    /* For smaller screens */
    @media (max-width: 768px) {
        padding: 20px;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        padding: 15px;
    }
`;

const FormTitle = styled.h2`
    font-size: 2rem;
    color: #FF5F1F;
    text-shadow: 0 0 3px #FF5F1F, 0 0 20px #FF5F1F;
    margin-bottom: 40px;
    font-family: 'RetroFont', sans-serif;

    /* For smaller screens */
    @media (max-width: 768px) {
        font-size: 1.8rem;
        margin-bottom: 30px;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }
`;


const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;

    /* Adjust for mobile devices */
    @media (max-width: 768px) {
        gap: 12px;
    }
`;



const Input = styled.input`
    font-family: 'RetroFont', serif;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #6A7FDB;
    border-radius: 5px;
    background-color: #1A1A1A;
    color: white;
    outline: none;

    &::placeholder {
        color: #888;
        font-family: 'RetroFont', serif;
    }

    /* For smaller screens */
    @media (max-width: 768px) {
        padding: 8px;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        padding: 6px;
    }
`;

const Textarea = styled.textarea`
    font-family: 'RetroFont', serif;
    padding: 10px;
    font-size: 1rem;
    border: 2px solid #6A7FDB;
    border-radius: 5px;
    background-color: #1A1A1A;
    color: white;
    outline: none;
    resize: none;
    height: 150px;

    &::placeholder {
        color: #888;
        font-family: 'RetroFont', serif;
    }

    /* For smaller screens */
    @media (max-width: 768px) {
        padding: 8px;
        height: 120px;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        padding: 6px;
        height: 100px;
    }
`;

const Menu = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    width: 100%;

    /* For smaller screens */
    @media (max-width: 768px) {
        margin-top: 20px;
    }
`;

const MenuItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ selected }) => (selected ? '#FF5F1F' : '#FFD700')};
    font-size: 1.5rem;
    font-family: 'RetroFont', sans-serif;
    text-decoration: none;
    margin: 20px;
    cursor: pointer;
    position: relative;
    padding: 10px 30px;
    transition: color 0.3s ease;

    &:hover {
        color: #FF5F1F;
    }

    /* For smaller screens */
    @media (max-width: 768px) {
        font-size: 1.3rem;
        margin: 15px;
        padding: 8px 25px;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        font-size: 1.2rem;
        margin: 10px;
        padding: 6px 20px;
    }
`;

const MenuText = styled.span`
    color: #FF5F1F;
    margin-right: 20px;
    &.blinking {
        animation: blink 1s infinite;
    }

    @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

const Arrow = styled.span`
    position: absolute;
    left: 5px;
    color: #FF5F1F;
    font-size: 1.5rem;

    /* For smaller screens */
    @media (max-width: 768px) {
        font-size: 1.3rem;
    }

    /* For extra small devices */
    @media (max-width: 480px) {
        font-size: 1.2rem;
    }
`;
