import React from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo.png';
import '../assets/fonts/fonts.css';

const Navbar = ({ handleNavbarClick }) => {
    return (
        <Nav>
            <LogoContainer>
                <Logo src={logo} alt="logo" />
                <Title>PHOONICKED</Title>
            </LogoContainer>
            <NavLinks>
                <NavLink onClick={() => handleNavbarClick('/about')}>About</NavLink>
                <NavLink onClick={() => handleNavbarClick('/resume')}>Resume</NavLink>
                <NavLink onClick={() => handleNavbarClick('/projects')}>Projects</NavLink>
                <ContactButton onClick={() => handleNavbarClick('/contact')}>Contact</ContactButton>
            </NavLinks>
        </Nav>
    );
};

export default Navbar;

const Nav = styled.nav`
  background: linear-gradient(90deg, #1A1836 0%, #3C3A63 50%, #1A1836 100%);
  margin-top: 20px;
  padding: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid;
  border-image: linear-gradient(90deg, #B0BEC5, #CFD8DC, #B0BEC5) 1;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

  /* Responsive adjustments */
  @media (max-width: 768px) {
    padding: 8px 0;
  }

  @media (max-width: 480px) {
    padding: 5px 0;
    justify-content: center; /* Center items for smaller screens */
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;

  @media (max-width: 480px) {
    padding: 5px 10px;
  }
`;

const Logo = styled.img`
  height: 60px;
  margin-left: 0;
  filter: drop-shadow(0 0 5px #FF5F1F);

  @media (max-width: 768px) {
    height: 50px;
  }

  @media (max-width: 480px) {
    height: 40px;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 3rem;
  padding-left: 10px;
  font-family: 'RetroFont', sans-serif;
  text-shadow: 0 0 10px rgba(255, 95, 31, 0.8), 0 0 20px rgba(255, 95, 31, 0.7), 0 0 30px rgba(255, 95, 31, 0.6);

  animation: flicker 2s infinite alternate;

  @keyframes flicker {
    0%, 100% {
      text-shadow: 0 0 10px rgba(255, 95, 31, 0.8), 0 0 20px rgba(255, 95, 31, 0.7), 0 0 30px rgba(255, 95, 31, 0.6);
    }
    50% {
      text-shadow: 0 0 5px rgba(255, 95, 31, 0.5), 0 0 15px rgba(255, 95, 31, 0.5), 0 0 20px rgba(255, 95, 31, 0.4);
    }
    80% {
      text-shadow: 0 0 15px rgba(255, 95, 31, 0.9), 0 0 25px rgba(255, 95, 31, 0.9), 0 0 35px rgba(255, 95, 31, 0.8);
    }
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
    padding-left: 5px;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
    padding-left: 0;
  }
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-family: 'RetroFont', sans-serif;

  @media (max-width: 768px) {
    display: none; /* Hide links on tablets and smaller screens */
  }
`;

const NavLink = styled.a`
  color: #FF5F1F;
  margin-left: 20px;
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;

  &:hover {
    animation: blink 1s infinite;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const ContactButton = styled.a`
  color: #FF5F1F;
  background: white;
  padding: 8px 16px;
  border-radius: 20px;
  margin-left: 20px;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #FF5F1F;
    color: white;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 4px 10px;
    font-size: 12px;
  }
`;