import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";

const Dashboard = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();
    const [projects, setProjects] = useState([]);
    const [newProject, setNewProject] = useState({ name: "", description: "", image: "", link: "", roles: [] });

    useEffect(() => {
    const checkAccess = async () => {
        try {
            // Avoid triggering unnecessary Firebase API calls
            const currentUser = auth.currentUser;

            if (!currentUser) {
                console.warn("Access denied: User is not authenticated.");
                navigate("/crm", { replace: true });
                return;
            }

            const userDocRef = doc(db, "users", currentUser.email);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.exists() || !userDoc.data().twoFAVerified) {
                console.warn("Access denied: 2FA not verified.");
                navigate("/crm", { replace: true });
                return;
            }

            console.log("Access granted: User is authenticated and 2FA verified.");
        } catch (error) {
            console.error("Error checking access:", error);
            navigate("/crm", { replace: true });
        }
    };

    checkAccess();
}, [navigate]);


    // Fetch projects from Firestore
    const fetchProjects = async () => {
        try {
            if (!auth.currentUser) {
                throw new Error("User is not authenticated");
            }

            const querySnapshot = await getDocs(collection(db, "projects"));
            const projectsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setProjects(projectsData);
        } catch (error) {
            console.error("Error fetching projects:", error);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    const handleAddProject = async () => {
        if (!newProject.name || !newProject.description) {
            alert("Please fill in all required fields.");
            return;
        }
        try {
            await addDoc(collection(db, "projects"), newProject);
            fetchProjects();
            setNewProject({ name: "", description: "", image: "", link: "", roles: [] });
        } catch (error) {
            console.error("Error adding project:", error);
        }
    };

    const handleDeleteProject = async (id) => {
        try {
            await deleteDoc(doc(db, "projects", id));
            fetchProjects();
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    const handleEditProject = async (id, updatedProject) => {
        try {
            await updateDoc(doc(db, "projects", id), updatedProject);
            fetchProjects();
        } catch (error) {
            console.error("Error updating project:", error);
        }
    };

    const handleLogout = async () => {
        try {
            await setDoc(
                doc(db, "users", auth.currentUser.email),
                { twoFAVerified: false },
                { merge: true }
            );
            await signOut(auth);
            console.log("User logged out and 2FA state cleared.");
            navigate("/crm", { replace: true });
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };    

    return (
        <DashboardContainer>
            <Sidebar>
                <SidebarItem active>Manage Projects</SidebarItem>
                <SidebarItem onClick={handleLogout}>Logout</SidebarItem>
            </Sidebar>

            <MainContent>
                <SectionHeader>Manage Projects</SectionHeader>
                <ProjectsTable>
                    {projects.map((project) => (
                        <ProjectRow key={project.id}>
                            <ProjectName>{project.name}</ProjectName>
                            <Actions>
                                <EditButton
                                    onClick={() =>
                                        handleEditProject(project.id, {
                                            ...project,
                                            name: `${project.name} (Edited)`,
                                        })
                                    }
                                >
                                    Edit
                                </EditButton>
                                <DeleteButton onClick={() => handleDeleteProject(project.id)}>Delete</DeleteButton>
                            </Actions>
                        </ProjectRow>
                    ))}
                </ProjectsTable>

                <AddProjectSection>
                    <Input
                        type="text"
                        placeholder="Project Name"
                        value={newProject.name}
                        onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
                    />
                    <Input
                        type="text"
                        placeholder="Project Description"
                        value={newProject.description}
                        onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
                    />
                    <Input
                        type="text"
                        placeholder="Image File Name"
                        value={newProject.image}
                        onChange={(e) => setNewProject({ ...newProject, image: e.target.value })}
                    />
                    <Input
                        type="text"
                        placeholder="Project Link"
                        value={newProject.link}
                        onChange={(e) => setNewProject({ ...newProject, link: e.target.value })}
                    />
                    <AddButton onClick={handleAddProject}>Add Project</AddButton>
                </AddProjectSection>
            </MainContent>
        </DashboardContainer>
    );
};

export default Dashboard;

// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const Sidebar = styled.div`
  width: 250px;
  padding: 20px;
  background-color: #1a1836;
`;

const SidebarItem = styled.div`
  margin: 15px 0;
  padding: 15px;
  cursor: pointer;
  color: white;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 30px;
`;

const SectionHeader = styled.h2`
  color: #ff5f1f;
`;

const ProjectsTable = styled.div`
  margin-bottom: 20px;
`;

const ProjectRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #2d2a4a;
  margin: 5px 0;
`;

const ProjectName = styled.div`
  color: white;
`;

const Actions = styled.div``;

const EditButton = styled.button`
  margin-right: 10px;
  background-color: #ff5f1f;
  border: none;
  color: black;
`;

const DeleteButton = styled(EditButton)`
  background-color: #d94d1a;
`;

const AddProjectSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
`;

const AddButton = styled.button`
  background-color: #ff5f1f;
  color: black;
  border: none;
`;
