import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const ProtectedRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const checkAuthentication = async () => {
            const currentUser = auth.currentUser;

            if (!currentUser) {
                setIsAuthenticated(false);
                return;
            }

            const userDocRef = doc(db, "users", currentUser.email);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists() && userDoc.data().twoFAVerified) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
        };

        checkAuthentication();
    }, []);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // Or a spinner
    }

    if (!isAuthenticated) {
        return <Navigate to="/crm" replace />;
    }

    return children;
};

export default ProtectedRoute;