import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useNavigate } from "react-router-dom";
import { db } from "../firebase"; // Firestore instance
import { collection, getDocs } from "firebase/firestore";

const Projects = () => {
    const [selectedCartridge, setSelectedCartridge] = useState(null); // Store the selected cartridge
    const [isInserted, setIsInserted] = useState(false); // Track if the cartridge is inserted
    const [fadeOutStarted, setFadeOutStarted] = useState(false);
    const [isSlottingOut, setIsSlottingOut] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [showWaitingMessage, setShowWaitingMessage] = useState(false);
    const [isInserting, setIsInserting] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [projects, setProjects] = useState([]); // Projects data from Firestore
    const readerRef = useRef(null);
    const navigate = useNavigate();
    const visibleCards = 4; // Number of visible cards in the carousel
    const totalCards = projects.length;

  const mapRoles = (rolesArray) => {
    if (!rolesArray || !Array.isArray(rolesArray)) return [];
    return [
      { label: "Role", description: rolesArray[0] || "N/A" },
      { label: "Tech Stack", description: rolesArray[1] || "N/A" },
      { label: "Achievements", description: rolesArray[2] || "N/A" },
    ];
  };
  
  const fetchProjects = async () => {
    try {
      const projectsCollection = collection(db, "projects");
      const projectsSnapshot = await getDocs(projectsCollection);
      const projectsData = projectsSnapshot.docs.map(doc => {
        const project = doc.data();
        return {
          ...project,
          roles: mapRoles(project.roles), // Map Firestore roles array to proper format
        };
      });
      setProjects(projectsData);
    } catch (error) {
      console.error("Error fetching projects: ", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

    const handleCarouselNext = () => {
        setCurrentIndex((prev) => (prev + 1) % totalCards); // Move to the next index, circular
    };

    const handleCarouselPrev = () => {
        setCurrentIndex((prev) => (prev - 1 + totalCards) % totalCards); // Move to the previous index, circular
    };

    const getVisibleCards = () => {
        return Array.from({ length: visibleCards }).map((_, i) => {
            // Calculate the circular index for each visible card
            const index = (currentIndex + i) % totalCards;
            return projects[index];
        });
    };

    const handleCartridgeClick = (index, cardRef) => {
        // Map the visible index to the actual project index
        const actualIndex = (currentIndex + index) % totalCards;

        // Get the position of the reader and the card
        const readerBounds = readerRef.current.getBoundingClientRect();
        const cardBounds = cardRef.current.getBoundingClientRect();
        const readerCenterX = readerBounds.left + readerBounds.width / 2 - cardBounds.width / 2;
        const readerCenterY = readerBounds.top + readerBounds.height / 2 - cardBounds.height / 2;

        setSelectedCartridge({ index: actualIndex, readerCenterX, readerCenterY });
        setFadeOutStarted(true);
        setIsInserting(true);

        // Show the waiting message after fade-out
        setTimeout(() => {
            setShowWaitingMessage(true);
        }, 500);

        setTimeout(() => {
            setIsInserted(true);
            setShowWaitingMessage(false);  // Hide waiting message once slotted
            setIsInserting(false);
        }, 1500); // Adjust timing if needed
    };

    const handleUnslot = () => {
        setIsSlottingOut(true); // Start the slotting out process
        setIsInserted(false); // Close the cartridge
        setSelectedCartridge(null); // Reset selected cartridge
        setIsSlottingOut(false); // Re-enable selecting other cartridges
        setFadeOutStarted(false);
        setShowStats(false);
    };

    const handleShowStats = () => setShowStats(true);
    const handleCloseStats = () => setShowStats(false);

    const handleReturn = () => {
        navigate('/');
    };

    if (projects.length === 0) {
        return <LoadingMessage>Loading Projects...</LoadingMessage>;
    }

    const getImagePath = (fileName) => {
      try {
        return require(`../assets/images/${fileName}`);
      } catch (error) {
        console.warn(`Image not found: ${fileName}, using fallback.`);
        return require(`../assets/images/logo.png`);
      }
    };

    return (
        <ProjectsContainer>
            <SlotTitle>My Projects</SlotTitle>
            <CarouselContainer>
                {!isInserted && !isInserting && (
                    <CarouselButton onClick={handleCarouselPrev}>
                        {"<"}
                    </CarouselButton>
                )}
                {getVisibleCards().map((project, index) => {
                    const cardRef = React.createRef();
                    return (
                        <GameCard
                            key={currentIndex + index}
                            ref={cardRef}
                            index={index}
                            selected={selectedCartridge?.index === (currentIndex + index) % totalCards}
                            isInserted={isInserted}
                            fadeOutStarted={fadeOutStarted}
                            readerCenterX={selectedCartridge?.readerCenterX}
                            readerCenterY={selectedCartridge?.readerCenterY}
                            onClick={() => !isInserted && !isSlottingOut && handleCartridgeClick(index, cardRef)}
                        >
                            <CardTop>
                                <CardTitle>{project.name}</CardTitle>
                            </CardTop>
                            <CardBody>
                                <CardImage src={getImagePath(project.image)} alt={project.name} />
                            </CardBody>
                            <Barcode />
                        </GameCard>
                    );
                })}
                {!isInserted && !isInserting && (
                    <CarouselButton onClick={handleCarouselNext}>
                        {">"}
                    </CarouselButton>
                )}
            </CarouselContainer>
            <ReaderContainer ref={readerRef}>
                <Screen>
                    {isInserted ? (
                        <>
                            <InsertedMessage>{projects[selectedCartridge?.index]?.name}</InsertedMessage>
                            <ProjectDescription>
                                {projects[selectedCartridge?.index]?.description}
                            </ProjectDescription>
                        </>
                    ) : showWaitingMessage ? (
                        <WaitingMessage>Inserting Cartridge...</WaitingMessage>
                    ) : (
                        <InsertPrompt>Insert Cartridge</InsertPrompt>
                    )}
                </Screen>
                <ButtonGroup>
                    <DeviceButtons onClick={handleReturn}>Return</DeviceButtons>
                    <DeviceButtons onClick={handleUnslot} disabled={isInserting}>Unslot</DeviceButtons>

                    {/* Add the retro arcade button for the roles */}
                    {projects[selectedCartridge?.index]?.roles && (
                        <DeviceButtons onClick={handleShowStats}>
                            View Roles
                        </DeviceButtons>
                    )}

                    {/* Add the retro arcade button for the link */}
                    {projects[selectedCartridge?.index]?.link && (
                        <ArcadeLinkButton
                            href={projects[selectedCartridge?.index]?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit Project
                        </ArcadeLinkButton>
                    )}
                </ButtonGroup>
            </ReaderContainer>

            {showStats && (
                <StatsOverlay onClick={handleCloseStats}>
                    <StatsModal onClick={(e) => e.stopPropagation()}>
                        {projects[selectedCartridge?.index]?.roles.map((stat, idx) => (
                            <StatItem key={idx}>
                                <StatLabel>{stat.label}:</StatLabel> {stat.description}
                            </StatItem>
                        ))}
                        <CloseButton onClick={handleCloseStats}>Close</CloseButton>
                    </StatsModal>
                </StatsOverlay>
            )}
        </ProjectsContainer>
    );
};

export default Projects;

// Keyframes for the animation
const moveToCenter = keyframes`
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate(
                ${({ readerCenterX }) => readerCenterX}px,
                ${({ readerCenterY }) => readerCenterY}px
        ) scale(1);
    }
`;

const slotCartridge = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(52px); /* Adjust this value based on the height of the reader */
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
      visibility: hidden; /* Optional for making the faded-out cards non-clickable */
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Styled components
const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #1A1A1A;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS devices */

  @media (max-width: 768px) {
    height: auto; /* Allow content to expand on smaller devices */
    padding: 20px;
  }
`;

const SlotTitle = styled.h1`
  font-size: clamp(1.5rem, 2vw, 2.5rem); /* Responsive scaling */
  color: #FF5F1F;
  margin-bottom: 20px;
  font-family: 'RetroFont', sans-serif;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const GameCard = styled.div`
  background-color: #2D2A4A;
  width: 130px;
  height: 200px;
  border-radius: 5px;
  border: 10px solid #000000;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  position: ${({ selected }) => (selected ? "absolute" : "relative")};
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;

  /* Dynamically calculate position based on offset and index */
  transform: translateX(${({ index, offset, cardWidth }) => (index * cardWidth) + offset}px);

  ${({ fadeOutStarted, selected }) =>
        fadeOutStarted &&
        !selected &&
        css`
      animation: ${fadeOut} 0.5s ease forwards;
    `}

  ${({ isSlottingOut, fadeOutStarted }) =>
        !isSlottingOut &&
        !fadeOutStarted &&
        css`
      animation: ${fadeIn} 0.5s ease forwards;
    `}

  ${({ selected, readerCenterX, readerCenterY }) =>
        selected &&
        css`
      animation: ${moveToCenter} 0.7s ease forwards;
    `}

  ${({ selected, isInserted }) =>
        selected &&
        isInserted &&
        css`
      animation: ${slotCartridge} 0.7s ease forwards;
    `}

  @media (max-width: 768px) {
    width: 110px;
    height: 180px;
  }

  @media (max-width: 480px) {
    width: 90px;
    height: 150px;
  }
`;

const CardTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #FF5F1F;
  border-radius: 5px 5px 0 0;
  color: white;
`;

const CardTitle = styled.h2`
  font-size: 0.8rem;
  text-align: center;
  font-family: 'RetroFont', sans-serif;
  margin: 0;
  padding: 5px;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }

  @media (max-width: 480px) {
    font-size: 0.6rem;
  }
`;

const CardBody = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1A1836;

  @media (max-width: 480px) {
    height: 100px;
  }
`;

const CardImage = styled.img`
  width: 120px;
  height: auto;
  border-radius: 5px;

  @media (max-width: 480px) {
    width: 80px;
  }
`;

const Barcode = styled.div`
  width: 100%;
  height: 15px;
  background: repeating-linear-gradient(
    to right,
    #000,
    #000 2px,
    #FFF 2px,
    #FFF 4px
  );
  margin-top: 5px;
  border-radius: 0 0 5px 5px;
`;

const InsertedMessage = styled.h2`
  font-size: clamp(1rem, 1.5vw, 1.3rem);
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const WaitingMessage = styled.h2`
  font-size: 1.5rem;
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;
  animation: blink 1s infinite;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  padding: 0 20px;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

/* Changed height from auto -> 30% */
const ReaderContainer = styled.div`
  background-color: #1a1a1a;
  width: 80%;
  max-width: 500px; /* Limit reader width */
  height: 30%;
  border: 6px solid #333;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  z-index: 999;

  /* Check if height works on smaller devices */

  @media (max-width: 768px) {
    width: 90%; /* Wider for smaller screens */
    height: 25%;
    padding: 15px;
  }

  @media (max-width: 480px) {
    width: 95%;
    height: 20%; 
    padding: 10px;
  }
`;

const Screen = styled.div`
  background-color: #111;
  width: 100%;
  max-width: 400px;
  height: 50%;
  border: 4px solid #000;
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  padding: 10px;

  @media (max-width: 768px) {
    max-height: 40%; /* Reduce height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 35%;
  }
`;

const ProjectDescription = styled.p`
  font-size: 0.9rem; /* Adjust the font size */
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1.4; /* Line height for readability */
  font-family: 'RetroFont', sans-serif;
  text-align: left; /* Justify the text for better readability */

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
  }

  @media (max-width: 480px) {
    gap: 5px;
  }
`;

const DeviceButtons = styled.button`
  background-color: #444;
  color: white;
  border: 2px solid #222;
  border-radius: 10px;
  padding: 7px 20px;
  margin: 10px 0;
  font-family: 'RetroFont', sans-serif;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: #666;
    transform: translateY(-2px);
  }

  @media (max-width: 480px) {
    padding: 5px 15px;
  }
`;

const InsertPrompt = styled.h2`
  font-size: 1.2rem;
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const ArcadeLinkButton = styled.a`
    background-color: #FF5F1F;
    color: white;
    border: 2px solid #222;
    border-radius: 10px;
    padding: 7px 20px;
    font-family: 'RetroFont', sans-serif;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    margin-left: 10px; /* Space between buttons */

    &:hover {
        background-color: #D94D1A;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0); /* Slight press effect */
    }

    &::before {
        content: '► ';
    }
`;

const StatsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StatsModal = styled.div`
  background-color: #1A1A1A;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  border: 2px solid #FF5F1F;
  font-family: 'RetroFont', sans-serif;

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const StatItem = styled.div`
  margin-bottom: 10px;
  color: #FFF;
  text-align: left;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const StatLabel = styled.span`
  font-weight: bold;
  color: #FF5F1F;
`;

const CloseButton = styled.button`
  background-color: #FF5F1F;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  font-family: 'RetroFont', sans-serif;

  &:hover {
    background-color: #D94D1A;
  }

  @media (max-width: 480px) {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(${(props) => props.offset}px);


  @media (max-width: 768px) {
    gap: 5px; /* Adjust spacing for smaller screens */
  }

  @media (max-width: 480px) {
    gap: 2px; /* Reduce further on very small screens */
  }
`;

const CarouselButton = styled.button`
justify-content: center;
align-items: center;
flex-wrap: wrap;
  top: 50%;
  transform: translateY(-50%);
  background-color: #FF5F1F;
  color: #FFF;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 1;
  font-family: 'RetroFont', sans-serif;

  &:hover {
    background-color: #D94D1A;
  }

  ${(props) => (props.disabled ? "opacity: 0.5; cursor: not-allowed;" : "")};
  left: ${(props) => (props.children === "<" ? "10px" : "auto")};
  right: ${(props) => (props.children === ">" ? "10px" : "auto")};

  @media (max-width: 480px) {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
`;

const LoadingMessage = styled.p`
  font-size: 1.2rem;
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;
`;